<template>
    <div class="RoleList">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <el-form :inline="true" class="demo-form-inline" :model="equipForm" ref="equipForm">
                    <el-form-item label="装备包名称">
                        <el-input v-model="equipForm.bagName" placeholder="请输入装备包名称"></el-input>
                    </el-form-item>
                    <el-form-item label="经办人">
                        <el-input v-model="equipForm.workJbr" placeholder="请输入经办人姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="工作日期">
                        <el-date-picker v-model="equipForm.workDate" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
                            type="date" placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button  class="btn" @click="handleSearch" icon="el-icon-search">查询</el-button>
                        <el-button @click="handleReset" icon="el-icon-refresh-right">重置</el-button>
                    </el-form-item>
                </el-form>
                <div class="clearfix">
                    <el-button  class="btn" icon="el-icon-circle-plus-outline" @click="$router.push({ name: 'equipManage-create' })">新增</el-button>
                </div>
            </div>

            <!-- 列表 -->
            <el-table :data="equipList" border  style="width: 100%; font-size: 13px;"
                :header-cell-style="{ background: '#eee', fontWeight: 700, color: '#606266' }">
                <el-table-column prop="seq" label="序号" align="center">
                </el-table-column>
                <el-table-column prop="bagName" label="装备包名称" align="center"> </el-table-column>
                <el-table-column prop="workDate" label="工作日期" align="center">
                </el-table-column>
                <el-table-column prop="workContent" label="工作内容" align="center">
                </el-table-column>
                <el-table-column prop="workJbr" label="经办人" align="center" width="160">
                </el-table-column>
                <el-table-column prop="deviceNumber" label="仪器数量" align="center" width="100">
                </el-table-column>
                <el-table-column prop="status_text" label="装备包状态" align="center" width="100">
                </el-table-column>

                <el-table-column label="操作" align="center" width="180">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" title="详情" @click="handleDetail(scope.row)">详情</el-button>
                        <el-button type="text" size="mini" v-if="scope.row.status==='00'" title="编辑" @click="handleEdit(scope.row)">编辑</el-button>
                        <el-button type="text" size="mini" title="出库" @click="handleCk(scope.row)" v-if="scope.row.status==='00'">出库</el-button>
                        <el-button type="text" size="mini" title="删除" @click="del(scope.row)"
                            class="delColor">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="equipForm.pageNum" :page-sizes="[5, 10, 20, 50, 100]" :page-size="equipForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>


        </el-card>
    </div>
</template>

<script>
import { serchEquipBag, delEquipBag } from '@/api/deviceEquipbag.js'
export default {
    data() {
        return {
            equipList: [],
            equipForm: {
                bagName: null,
                workJbr: null,
                workDate: null,
                pageNum: 1,
                pageSize: 10
            },
            total: 0,
        }
    },
    mounted() {
        this.loadEquipInfo()
    },
    methods: {
        handleSizeChange(val) {
            this.equipForm.pageSize = val;
            this.equipForm.pageNum = 1;
            this.loadEquipInfo();
        },
        handleCurrentChange(val) {
            this.equipForm.pageNum = val;
            this.loadEquipInfo();
        },

        //查询角色信息
        loadEquipInfo() {
            serchEquipBag(this.equipForm).then(res => {
                if (res.code === '000000') {
                    this.equipList = res.t.list
                    this.total = res.t.total
                    let count = 1;
                    this.equipList.forEach((item) => {
                        item.seq = count++
                        item.status === '00' ? (item.status_text = '正常') : (item.status_text = '失效')


                    })

                }
            })
        },
        handleEdit({ id }) {
            this.$router.push({
                name: 'equipManage-edit',
                params: {
                    id
                }
            })
        },
        handleDetail({ id }) {
            this.$router.push({
                name: 'equipManage-detail',
                params: {
                    id
                }
            })
        },
        handleCk({ id }) {
            this.$router.push({
                name: 'equipManage-ckInventory',
                params: {
                    id
                }
            })
        },


        //删除
        del({id,bagName}) {
            this.$confirm('是否删除名称为“'+bagName+'”的装备包？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delEquipBag(id).then(res => {
                    if (res.code === '000000') {
                        this.$message.success('删除成功')
                        this.loadEquipInfo()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                })
            })

        },
        //搜索
        handleSearch() {
            this.loadEquipInfo(this.equipForm);
        },
        //重置
        handleReset() {
            this.equipForm = {};
            this.loadEquipInfo(this.equipForm);
        },


    }

}
</script>


<style lang="scss" scoped>
.RoleList {

    .el-pagination,
    .clearfix {
        text-align: left;
    }

    .delColor {
        color: red;
    }

}
</style>